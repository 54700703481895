import { useEffect, useState } from "react"
import authClient from "../clients/authClient"

const useUser = () => {
  const [user, setUser] = useState(null)
  const sessionId = localStorage.getItem('AddSessionId')

  useEffect(() => {
    const fetchUser = async () => {
      const _user = await authClient.user()
      if (_user) {
        setUser({ ..._user, sessionId })
      } else {
        setUser(null)
      }
    }

    if (!sessionId) {
      setUser(null)
      // Try to fetch user anyway
      fetchUser()
    } else if (!user || user.sessionId !== sessionId) {
      fetchUser()
    }
  }, [sessionId])

  return user
}


export default useUser
