import { Accordion, AccordionDetails, AccordionItem, AccordionSummary } from "./Accordion"

const Pandgegevens = ({ objects, fields, accordion }) => {
  if (objects.length <= 1) {
    return objects.map(object => (
      <table className="table details" key={`details-${object.id}`}>
        <tbody>
          {Object.keys(fields).map(key => {
            const field = fields[key]
            const _value = key === 'headline' ? object.headline : object.properties[key]
            const value = field.format ? field.format(_value, object) : _value

            if (!value) {
              return null
            }

            return <tr key={key}>
              <td>{field.label}</td>
              <td>{value}</td>
              {field.secondValue && <td>{field.secondValue(object)}</td>}
            </tr>
          })}
        </tbody>
      </table>
    ))
  } else if (!accordion) {
    return <>
      {objects.map(object => (
        <div key={object.id} style={{ paddingTop: 14 }}>
          <h5 className="section-header">{object.headline} <span>{object.properties.objectgroep}</span></h5>
          <table className="table details">
            <tbody key={object.id}>
              {Object.keys(fields).map(key => {
                const field = fields[key]
                const _value = key === 'headline' ? object.headline : object.properties[key]
                const value = field.format ? field.format(_value, object) : _value

                if (!value) {
                  return null
                }

                return <tr key={key}>
                  <td>{field.label}</td>
                  <td>{value}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      ))}
    </>
  } else {
    return <Accordion multiExpand>
      {objects.map(object => (
        <AccordionItem key={object.id}>
          <AccordionSummary>
            <h5>{object.headline} <span>{object.properties.objectgroep}</span></h5>
          </AccordionSummary>
          <AccordionDetails>
            <table className="table details">
              <tbody key={object.id}>
                {Object.keys(fields).map(key => {
                  const field = fields[key]
                  let value

                  if (field.custom) {
                    value = field.custom(object)
                  } else {
                    const _value = key === 'headline' ? object.headline : object.properties[key]
                    value = field.format ? field.format(_value, object) : _value
                  }

                  if (!value) {
                    return null
                  }

                  return <tr key={key}>
                    {field.label && <td>{field.label}</td>}
                    <td colSpan={field.label ? null : 2}>{value}</td>
                  </tr>
                })}
              </tbody>
            </table>
          </AccordionDetails>
        </AccordionItem>
      ))}
    </Accordion>
  }
}

export default Pandgegevens
