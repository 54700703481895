import Topbar from "../components/Topbar"

const Frontend = ({ children }) => {
  return <>
    <Topbar/>

    {children}

    <footer className="footer">
      <div className="container center aligned">
        &copy; REND {new Date().getFullYear()}
      </div>
    </footer>
  </>
}

export default Frontend
