import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"
import objectsClient from "../clients/objectsClient"
import Modal from "../components/Modal"
import { formatCurrency, formatDate, numberToText } from "../functions"
import Frontend from "../layouts/Frontend"
import config from '../config'

import 'swiper/css'
import 'swiper/css/navigation'
import Energielabel from "../components/Energielabel"
import useMediaQuery from "../hooks/useMediaQuery"

const ObjectDetail = () => {
  SwiperCore.use([Navigation])

  const navigate = useNavigate()
  const [printUrl,setPrintUrl] = useState(null)
  const [printPages,setPrintPages] = useState([ 1 ])
  const [modalOpen,setModalOpen] = useState(false)
  const [swiperOpen,setSwiperOpen] = useState(false)
  const [swiperSlide,setSwiperSlide] = useState(0)
  const isDesktop = useMediaQuery('(min-width: 600px)')

  const { id } = useParams()

  const selectPrintPage = (page, selected) => {
    const index = printPages.indexOf(page)
    if (selected && index < 0) {
      setPrintPages([ ...printPages, page ])
    } else if (!selected && index >= 0) {
      const p = [ ...printPages ]
      p.splice(index, 1)
      setPrintPages(p)
    }
  }

  const goPrint = () => {
    setPrintUrl(null)
    setTimeout(() => {
      setPrintUrl(`/objects/${id}/print/${printPages.join('-')}`)
    }, 100)
  }

  const goDownload = () => {
    setPrintUrl(null)
    window.open(`${config.server.baseUri}/object/id/${id}/format/pdf/pages/${printPages.join('-')}`)
  }

  const { isLoading, data: object } = useQuery(
    [ 'object', id ],
    () => objectsClient.one(id)
  )

  useEffect(() => {
    if (object && !object.error) {
      document.title = `VHE: ${object.headline} | REND`
    }
  }, [object])

  if (isLoading) {
    navigate('/login')
    return <Frontend>
      <div className="container">
        <div className="loader"></div>
      </div>
    </Frontend>
  }

  if (object.error) {
    return <Frontend>
      <div className="container">
        <>{object.error}</>
      </div>
    </Frontend>
  }

  const media = [ ...object.media ]
  // if (media.length === 0) {
  //   media.push({
  //     url: '/images/noimage.png',
  //     thumb: '/images/noimage.png',
  //   })
  // }
  const thumbs = media.length > 5 ? media.slice(0, 6) : media

  const financesTotal = {
    aflossing: 0,
    jaarlijkseAflossing: 0,
    huidigeLening: 0,
    hypotheek: 0
  }
  object.properties.finances.forEach(finance => {
    financesTotal.aflossing += finance.aflossing
    financesTotal.jaarlijkseAflossing += finance.jaarlijkseAflossing
    financesTotal.huidigeLening += finance.huidigeLening
    financesTotal.hypotheek += finance.hypotheek
  })

  return <Frontend>
    <div className="container">
      <div className="object-header">
        <div className="content">
          <h2>Object: {object.headline}</h2>
          <div className="meta">
            {object.properties.beleggers.length > 1
              ? <>Eigenaars: {object.properties.beleggers.filter(b => !!b.name).map(belegger => belegger.name).join(', ')}</>
              : <>Eigenaar: {object.properties.beleggers[0].name}</>}
          </div>
          <div className="actions"><Link to="/" className="link">Terug naar het overzicht</Link></div>
        </div>
        <div className="right">
          <button type="button" id="print_button" className="blue solid button" onClick={() => setModalOpen(true)}>Afdrukken of downloaden</button>
        </div>
      </div>
    </div>

    {media.length > 0 && <div className="container">
      {isDesktop
        ? <div className={`mainsub ${numberToText(Math.max(Math.min(media.length, 5), 1))} ratio-16x9 rounded`}>
            {thumbs.map((file, index) => (
              <div key={`thumb-${index}`} className="item">
                <div onClick={() => { setSwiperSlide(index); setSwiperOpen(true) }} className="crop-image" style={{ backgroundImage: `url(${file.thumb})` }}></div>
              </div>
            ))}
            {media.length > 5 && <div className="actions">
              <div onClick={() => { setSwiperSlide(5); setSwiperOpen(true) }} className="button solid white">Alle foto's tonen</div>
            </div>}
          </div>
        : <div className="mainsub one ratio-16x9 rounded">
            <div key="thumb-0" className="item">
              <div onClick={() => { setSwiperSlide(0); setSwiperOpen(true) }} className="crop-image" style={{ backgroundImage: `url(${thumbs[0].thumb})` }}></div>
            </div>
            {media.length > 1 && <div className="actions">
              <div onClick={() => { setSwiperSlide(1); setSwiperOpen(true) }} className="button solid white">Alle foto's tonen</div>
            </div>}
          </div>
      }
    </div>}

    <div className="container">
      <div className="content-column">

        {object.properties.introtext && <p className="lead">{object.properties.introtext}</p>}

        {object.properties.fulltext && <div className="fulltext" dangerouslySetInnerHTML={{ __html: object.properties.fulltext }}/>}

        <h2>Gegevens VHE's</h2>
        <table className="table details">
          <tbody>
            <tr>
              <td>Adres</td>
              <td>{object.headline}</td>
            </tr>
            <tr>
              <td>Eigenaar</td>
              <td>{object.properties.beleggers.filter(b => !!b.name).map(belegger => belegger.name).join(', ')}</td>
            </tr>
            {object.properties.extra && <tr>
              <td>Extra informatie</td>
              <td>{object.properties.extra}</td>
            </tr>}
            <tr>
              <td>Energielabel</td>
              <td>
                {object.properties.energielabel
                  ? <Energielabel label={object.properties.energielabel}/>
                  : 'geen'}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <a className="link" rel="noreferrer" href={`https://www.google.com/maps/place/${encodeURIComponent(object.properties.adres + ' ' + object.properties.nummer + ', ' + object.properties.postcode + ' ' + object.properties.plaats).replace(/%20/g, '+').replace(/%2C/g, ',')}`} target="_blank">Bekijk op de kaart</a>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Huurinformatie</h3>
        <table className="table details">
          <tbody>
            <tr>
              <td>Huurder</td>
              <td>{object.properties.huurder}</td>
            </tr>
            <tr>
              <td>Aanvang huurcontract</td>
              <td>{formatDate(object.properties.ingangsdatum)}</td>
            </tr>
            <tr>
              <td>Verlengformule</td>
              <td>{object.properties.verlengformule}</td>
            </tr>
            <tr>
              <td>Huuropbrengst excl. BTW</td>
              <td>{formatCurrency(object.properties.huursomexcl)}</td>
            </tr>
            <tr>
              <td>Huuropbrengst incl. BTW</td>
              <td>{formatCurrency(object.properties.huursom)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Modal open={modalOpen} onClose={() => { setPrintUrl(null); setModalOpen(false) }}>
      <h2>Wat wilt u afdrukken?</h2>

      <div className="checkbox">
        <input type="checkbox" name="what[]" value="1" id="what_1" checked={printPages.includes(1)} onChange={e => selectPrintPage(1, e.target.checked)}/>
        <label htmlFor="what_1">Pagina 1: Omschrijving locatie</label>
      </div>
      <div className="checkbox">
        <input type="checkbox" name="what[]" value="2" id="what_2" checked={printPages.includes(2)} onChange={e => selectPrintPage(2, e.target.checked)}/>
        <label htmlFor="what_2">Pagina 2: Foto's</label>
      </div>
      <div className="checkbox">
        <input type="checkbox" name="what[]" value="3" id="what_3" checked={printPages.includes(3)} onChange={e => selectPrintPage(3, e.target.checked)}/>
        <label htmlFor="what_3">Pagina 3: Kosten en baten</label>
      </div>
      <div className="checkbox">
        <input type="checkbox" name="what[]" value="4" id="what_4" checked={printPages.includes(4)} onChange={e => selectPrintPage(4, e.target.checked)}/>
        <label htmlFor="what_4">Pagina 4: Opbrengsten uit verhuur</label>
      </div>
      {/* <div className="checkbox">
        <input type="checkbox" name="what[]" value="5" id="what_5" checked={printPages.includes(5)} onChange={e => selectPrintPage(5, e.target.checked)}/>
        <label htmlFor="what_5">Pagina 5: Huurinformatie</label>
      </div> */}

      <div style={{ marginTop: 20 }}>
        <button type="submit" className="solid blue button" onClick={goPrint}>Afdrukken</button>
        <button type="submit" className="outlined button" onClick={goDownload}>Download PDF</button>
      </div>

      {printUrl && <iframe title="Print" style={{ border: 'none', width: '100%', height: 0, position: 'absolute' }} src={printUrl} />}
    </Modal>

    <Modal open={swiperOpen} fullscreen onClose={() => setSwiperOpen(false)}>
      {swiperOpen && <Swiper className="photo-slideshow" initialSlide={swiperSlide} navigation>
        {media.map((image, index) => (
          <SwiperSlide key={`media-${index}`}>
            <div className="photo-slideshow-slide"><img src={image.url} alt={image.alt}/></div>
          </SwiperSlide>
        ))}
      </Swiper>}
    </Modal>
  </Frontend>

}

export default ObjectDetail
