import authFetch from '../authFetch'
import config from '../config'

const authClient = {
  login: async (username, password) => {
    const response = await fetch(
      `${config.server.authUri}/json-login`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify({
          username,
          password
        })
      }
    )
    const json = await response.json()
    return json
  },
  user: async () => {
    const result = await authFetch(`${config.server.baseUri}/user`)
    return result
  }
}

export default authClient
