import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import projectsClient from "../clients/projectsClient"
import { formatCurrency, formatDate, formatNumber } from "../functions"
import Pandgegevens from "../components/Pandgegevens"
import Energielabel from "../components/Energielabel"

const fieldsPandgegevens = {
  beleggers: {
    label: 'Eigenaar',
    format: value => value.map(b => b.name).join(', ')
  },
  headline: {
    label: 'Adres',
  },
  postcode: {
    label: 'Postcode',
  },
  extra: {
    label: 'Extra informatie'
  }
}

const firstFields = {
  huurder: {
    label: 'Huurder',
    format: value => value || 'geen'
  },
  ingangsdatum: {
    label: 'Aanvang huurcontract',
    format: formatDate
  },
  verlengformule: {
    label: 'Verlengformule',
  },
  huursomexcl: {
    label: 'Huuropbrengst excl. BTW',
    custom: object => object.properties.huurder ? formatCurrency(object.properties.huursomexcl) : null
  },
  huursom: {
    label: 'Huuropbrengst incl. BTW',
    custom: object => object.properties.huurder ? formatCurrency(object.properties.huursom) : null
  }
}

const ProjectPrint = () => {
  const [isPrinted, setIsPrinted] = useState(false)
  const { id, pages } = useParams()
  const renderPages = pages.split('-')

  const { isLoading, data: project } = useQuery(
    [ 'project', id ],
    () => projectsClient.one(id)
  )

  useEffect(() => {
    if (!isPrinted && !isLoading && project && !project.error) {
      window.print()
      setIsPrinted(true)
    }
  }, [isLoading, project, isPrinted])

  if (isLoading) {
    return <div className="container">
      <div className="loader"></div>
    </div>
  }

  if (project.error) {
    return <div className="container">
      <>{project.error}</>
    </div>
  }

  const media = [ ...project.media ]
  const beleggers = []
  const finances = {}
  const financesTotal = {
    aflossing: 0,
    jaarlijkseAflossing: 0,
    huidigeLening: 0,
    hypotheek: 0
  }

  project.objects.forEach(object => {
    object.properties.beleggers.forEach(belegger => {
      if (!beleggers.includes(belegger.name)) {
        beleggers.push(belegger.name)
      }
    })

    object.properties.finances.forEach(finance => {
      if (!(finance.leningNummer in finances)) {
        finances[finance.leningNummer] = { ...finance }

        financesTotal.aflossing += finance.aflossing
        financesTotal.jaarlijkseAflossing += finance.jaarlijkseAflossing
        financesTotal.huidigeLening += finance.huidigeLening
        financesTotal.hypotheek += finance.hypotheek
      }
    })
  })

  return <>
    {renderPages.includes('1') && media.length > 0 &&
    <div className="page">
      <img src="/images/logo.svg" width="180" alt="Logo" style={{ marginBottom: '1rem'}}/>
      <div className="object-header">
        <div className="content">
          <h2>Locatie: {project.headline}</h2>
          <div className="meta">
            {beleggers.length > 1
              ? <>Eigenaars: {beleggers.join(', ')}</>
              : <>Eigenaar: {beleggers.length > 0 ? beleggers[0] : 'geen'}</>}
          </div>
        </div>
      </div>

      <div className='grid gallery'>
        {media.map((file, index) => (
          <div key={`thumb-${index}`} className="item">
            <img src={file.thumb} alt={file.alt}/>
          </div>
        ))}
      </div>
    </div>}

    {renderPages.includes('2') &&
    <div className="page">
      <img src="/images/logo.svg" width="180" alt="Logo" style={{ marginBottom: '1rem'}}/>
      <div className="object-header">
        <div className="content">
          <h2>Locatie: {project.headline}</h2>
          <div className="meta">
            {beleggers.length > 1
              ? <>Eigenaars: {beleggers.join(', ')}</>
              : <>Eigenaar: {beleggers.length > 0 ? beleggers[0] : 'geen'}</>}
          </div>
        </div>
      </div>

      <div className="content-column fullwidth">
        <h2>Algemeen</h2>

        {project.properties.introtext && <p>{project.properties.introtext}</p>}

        {project.properties.fulltext && <div dangerouslySetInnerHTML={{ __html: project.properties.fulltext }}/>}
      </div>
    </div>}

    {renderPages.includes('3') &&
    <div className="page">
      <img src="/images/logo.svg" width="180" alt="Logo" style={{ marginBottom: '1rem'}}/>
      <div className="object-header">
        <div className="content">
          <h2>Locatie: {project.headline}</h2>
          <div className="meta">
            {beleggers.length > 1
              ? <>Eigenaars: {beleggers.join(', ')}</>
              : <>Eigenaar: {beleggers.length > 0 ? beleggers[0] : 'geen'}</>}
          </div>
        </div>
      </div>

      <div className="content-column fullwidth">
        <h2>Gegevens VHE's</h2>

        <Pandgegevens
          objects={project.objects}
          fields={fieldsPandgegevens}
        />
      </div>

      <h3>Energielabel</h3>
      <table className="table details">
        <tbody>
        {project.objects.map(object => (
          <tr key={object.id}>
            <td>{object.headline}</td>
            <td>{object.properties.energielabel ? <Energielabel label={object.properties.energielabel}/> : 'geen'}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>}

    {renderPages.includes('4') &&
    <div className="page">
      <img src="/images/logo.svg" width="180" alt="Logo" style={{ marginBottom: '1rem'}}/>
      <div className="object-header">
        <div className="content">
          <h2>Locatie: {project.headline}</h2>
          <div className="meta">
            {beleggers.length > 1
              ? <>Eigenaars: {beleggers.join(', ')}</>
              : <>Eigenaar: {beleggers.length > 0 ? beleggers[0] : 'geen'}</>}
          </div>
        </div>
      </div>

      <div className="content-column fullwidth">
        <h2>Huurinformatie</h2>

        {/* <table className="table">
          <thead>
            <tr>
              <th>VHE</th>
              <th>Huurder</th>
              <th>Huursom excl. BTW</th>
              <th>Huursom incl. BTW</th>
            </tr>
          </thead>
          <tbody>
            {project.objects.map(object => (
              <tr key={object.id}>
                <td>{object.headline}</td>
                <td>{object.properties.huurder || 'geen'}</td>
                <td>{object.properties.huurder && object.properties.huursomexcl ? formatCurrency(object.properties.huursomexcl) : '-'}</td>
                <td>{object.properties.huurder && object.properties.huursom ? formatCurrency(object.properties.huursom) : '-'}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}><strong>Totaal</strong></td>
              <td>{formatCurrency(project.objects.reduce((prev, object) => prev + (object.properties.huurder && object.properties.huursomexcl ? parseFloat(object.properties.huursomexcl) : 0), 0))}</td>
              <td>{formatCurrency(project.objects.reduce((prev, object) => prev + (object.properties.huurder && object.properties.huursom ? parseFloat(object.properties.huursom) : 0), 0))}</td>
            </tr>
          </tfoot>
        </table>

        <Pandgegevens
          objects={project.objects}
          fields={firstFields}
        /> */}

        <div className="details-table details-table-print">
          <div className="details-row details-head">
            <div className="w">VHE</div>
            <div className="w">Huurder</div>
            <div className="n">Huursom excl. BTW</div>
            <div className="n">Huursom incl. BTW</div>
          </div>
          {project.objects.map(object => (
            <div key={object.id}>
              <div className="details-row">
                <div className="w">{object.headline}</div>
                <div className="w">{object.properties.huurder || 'geen'}</div>
                <div className="n">{object.properties.huurder && object.properties.huursomexcl ? formatCurrency(object.properties.huursomexcl) : '-'}</div>
                <div className="n">{object.properties.huurder && object.properties.huursom ? formatCurrency(object.properties.huursom) : '-'}</div>
              </div>
              <table className="table details">
                <tbody>
                  <tr>
                    <td>Objectgroep</td>
                    <td>{object.properties.objectgroep}</td>
                    <td></td>
                  </tr>
                  {object.properties.huurder && <tr>
                    <td>Aanvang huurcontract</td>
                    <td>{formatDate(object.properties.ingangsdatum)}</td>
                    <td></td>
                  </tr>}
                  {object.properties.huurder && <tr>
                    <td>Verlengformule</td>
                    <td>{object.properties.verlengformule}</td>
                    <td></td>
                  </tr>}
                </tbody>
              </table>
            </div>
          ))}
          <div className="details-row details-foot">
            <div className="w">Totaal</div>
            <div className="w">&nbsp;</div>
            <div className="n">{formatCurrency(project.objects.reduce((prev, object) => prev + (object.properties.huurder && object.properties.huursomexcl ? parseFloat(object.properties.huursomexcl) : 0), 0), true)}</div>
            <div className="n">{formatCurrency(project.objects.reduce((prev, object) => prev + (object.properties.huurder && object.properties.huursom ? parseFloat(object.properties.huursom) : 0), 0), true)}</div>
          </div>
        </div>
      </div>
    </div>}


    {renderPages.includes('5') &&
    <div className="page">
      <img src="/images/logo.svg" width="180" alt="Logo" style={{ marginBottom: '1rem'}}/>
      <div className="object-header">
        <div className="content">
          <h2>Locatie: {project.headline}</h2>
          <div className="meta">
            {beleggers.length > 1
              ? <>Eigenaars: {beleggers.join(', ')}</>
              : <>Eigenaar: {beleggers.length > 0 ? beleggers[0] : 'geen'}</>}
          </div>
        </div>
      </div>

      <div className="content-column fullwidth">
        <h2>Financieel</h2>

        <h3>Totaal</h3>
        <table className="table details">
          <tbody>
            <tr>
              <td>Aankoopsom</td>
              <td>{formatCurrency(project.objects[0].properties.aanschafwaardepr)}</td>
              <td></td>
            </tr>
            <tr>
              <td>WOZ Waarde</td>
              <td>{formatCurrency(project.objects[0].properties.wozwaarde)}</td>
              <td></td>
            </tr>
            <tr>
              <td>Taxatiewaarde</td>
              <td>{formatCurrency(project.objects[0].properties.taxatiewaardepr)}</td>
              <td></td>
            </tr>
            {project.objects[0].properties.geschattewaarde && <tr>
              <td>Geschatte Waarde</td>
              <td>{project.objects[0].properties.geschattewaarde && project.objects[0].properties.geschattewaarde !== '0' ? formatCurrency(project.objects[0].properties.geschattewaarde) : '-'}</td>
              <td></td>
            </tr>}
          </tbody>
        </table>

        <h2>Hypotheek</h2>

        <div className="details-table details-table-print">
          <div className="details-row details-head">
            <div>Leningnummer</div>
            <div>Financierder</div>
            <div>Hypotheek bij aanvang</div>
            <div>Hypotheek actueel</div>
            <div>Hypothecaire inschrijving</div>
          </div>

          {Object.keys(finances).map(nr => (
            <div key={nr}>
              <div className="details-row">
                <div>{nr}</div>
                <div>{finances[nr].bedrijf}</div>
                <div>{formatCurrency(finances[nr].hypotheek, true)}</div>
                <div>{finances[nr].huidigeLening ? formatCurrency(finances[nr].huidigeLening) : '-'}</div>
                <div>{finances[nr].huidigeLening ? 'Ja' : 'Nee'}</div>
              </div>
              <table className="table details">
                <tbody>
                  <tr>
                    <td>Hypotheek bij aanvang</td>
                    <td>{formatCurrency(finances[nr].hypotheek)}</td>
                  </tr>
                  <tr>
                    <td>Jaarlijkse aflossing</td>
                    <td>{formatCurrency(finances[nr].jaarlijkseAflossing, true)}</td>
                  </tr>
                  <tr>
                    <td>Rente</td>
                    <td>{finances[nr].rente}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}

          <div className="details-row details-foot">
            <div><strong>Totaal</strong></div>
            <div>&nbsp;</div>
            <div>{formatCurrency(financesTotal.hypotheek, true)}</div>
            <div>{financesTotal.huidigeLening ? formatCurrency(financesTotal.huidigeLening, true) : 'afgelost'}</div>
            <div>&nbsp;</div>
          </div>
        </div>

        {/* <table className="table">
          <thead>
            <tr>
              <th>Leningnummer</th>
              <th>Financierder</th>
              <th>Hypotheek actueel</th>
              <th>Hypothecaire inschrijving</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(finances).map((nr, index) => (
              <tr key={`finances-${index}`}>
                <td>{nr}</td>
                <td>{finances[nr].bedrijf}</td>
                <td>{finances[nr].hypotheek ? formatCurrency(finances[nr].hypotheek) : '-'}</td>
                <td>Ja</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}><strong>Totaal</strong></td>
              <td>{financesTotal.hypotheek ? formatCurrency(financesTotal.hypotheek) : '-'}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>

        {Object.keys(finances).map((nr, index) => (
          <div key={nr} style={{ paddingTop: 14 }}>
            <h5 className="section-header">Leningnummer {nr}</h5>
            <table className="table details">
              <tbody>
                <tr>
                  <td>Financierder</td>
                  <td>{finances[nr].bedrijf}</td>
                </tr>
                <tr>
                  <td>Hypotheek actueel</td>
                  <td>{finances[nr].huidigeLening ? formatCurrency(finances[nr].huidigeLening) : 'afgelost'}</td>
                </tr>
                <tr>
                  <td>Hypothecaire inschrijving</td>
                  <td>Ja</td>
                </tr>
                <tr>
                  <td>Hypotheek bij aanvang</td>
                  <td>{formatCurrency(finances[nr].hypotheek)}</td>
                </tr>
                <tr>
                  <td>Jaarlijkse aflossing</td>
                  <td>{formatCurrency(finances[nr].jaarlijkseAflossing, true)}</td>
                </tr>
                <tr>
                  <td>Rente</td>
                  <td>{finances[nr].rente}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))} */}

        <h3>Loan to value</h3>
        <table className="table details">
          <tbody>
            <tr>
              <td>Loan to value</td>
              <td>
                {project.objects[0].properties.taxatiewaardepr && financesTotal.huidigeLening
                  ? formatNumber(100 * financesTotal.huidigeLening / project.objects[0].properties.taxatiewaardepr, 2) + '%'
                  : '-'
                }
              </td>
            </tr>
          </tbody>
        </table>

        {/* <h3>Beleggersinformatie</h3>
        <table className="table details">
          <tbody>
            <tr>
              <td>Bruto rendement bij aankoop</td>
              <td></td>
            </tr>
            <tr>
              <td>Bruto rendement huidig</td>
              <td></td>
            </tr>
            <tr>
              <td>Loan to value huidig</td>
              <td></td>
            </tr>
            <tr>
              <td>Loan to value bij aankoop</td>
              <td></td>
            </tr>
          </tbody>
        </table> */}
      </div>

      {/* <div className="content-column fullwidth">
        <h3>Opbrengsten uit verhuur</h3>
        <Pandgegevens objects={project.objects} fields={rentFields}/>
      </div> */}
    </div>}
  </>

}

export default ProjectPrint
