import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import objectsClient from "../clients/objectsClient"
import Energielabel from "../components/Energielabel"
import { formatCurrency, formatDate } from "../functions"

const ObjectPrint = () => {
  const [isPrinted, setIsPrinted] = useState(false)
  const { id, pages } = useParams()
  const renderPages = pages.split('-')

  const { isLoading, data: object } = useQuery(
    [ 'object', id ],
    () => objectsClient.one(id)
  )

  useEffect(() => {
    if (!isPrinted && !isLoading && object && !object.error) {
      window.print()
      setIsPrinted(true)
    }
  }, [isLoading, object, isPrinted])

  if (isLoading) {
    return <div className="container">
      <div className="loader"></div>
    </div>
  }

  if (object.error) {
    return <div className="container">
      <>{object.error}</>
    </div>
  }

  const media = [ ...object.media ]
  // if (media.length === 0) {
  //   media.push({
  //     url: '/images/noimage.png',
  //     thumb: '/images/noimage.png',
  //   })
  // }

  const financesTotal = {
    aflossing: 0,
    jaarlijkseAflossing: 0,
    huidigeLening: 0,
    hypotheek: 0
  }
  object.properties.finances.forEach(finance => {
    financesTotal.aflossing += finance.aflossing
    financesTotal.jaarlijkseAflossing += finance.jaarlijkseAflossing
    financesTotal.huidigeLening += finance.huidigeLening
    financesTotal.hypotheek += finance.hypotheek
  })

  return <>
      {renderPages.includes('1') && <div className="page">
        <img src="/images/logo.svg" width="180" alt="Logo" style={{ marginBottom: '1rem'}}/>
        <div className="object-header">
          <div className="content">
            <h2>VHE: {object.headline}</h2>
            <div className="meta">
              {object.properties.beleggers.length > 1
                ? <>Eigenaars: {object.properties.beleggers.map(belegger => belegger.name).join(', ')}</>
                : <>Eigenaar: {object.properties.beleggers[0].name}</>}
            </div>
          </div>
        </div>

        <div className=" fullwidth">

          <h2>Gegevens VHE's</h2>
          <table className="table details">
            <tbody>
              <tr>
                <td>Adres</td>
                <td>{object.headline}</td>
              </tr>
              <tr>
                <td>Eigenaar</td>
                <td>{object.properties.beleggers.filter(b => !!b.name).map(belegger => belegger.name).join(', ')}</td>
              </tr>
              {object.properties.extra && <tr>
                <td>Extra informatie</td>
                <td>{object.properties.extra}</td>
              </tr>}
              <tr>
                <td>Energielabel</td>
                <td>
                  {object.properties.energielabel
                    ? <Energielabel label={object.properties.energielabel}/>
                    : 'geen'}
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Huurinformatie</h3>
          <table className="table details">
            <tbody>
              <tr>
                <td>Huurder</td>
                <td>{object.properties.huurder}</td>
              </tr>
              <tr>
                <td>Aanvang huurcontract</td>
                <td>{formatDate(object.properties.ingangsdatum)}</td>
              </tr>
              <tr>
                <td>Verlengformule</td>
                <td>{object.properties.verlengformule}</td>
              </tr>
              <tr>
                <td>Huuropbrengst excl. BTW</td>
                <td>{formatCurrency(object.properties.huursomexcl)}</td>
              </tr>
              <tr>
                <td>Huuropbrengst incl. BTW</td>
                <td>{formatCurrency(object.properties.huursom)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>}

      {renderPages.includes('2') && media.length > 0 && <div className="page">
        <img src="/images/logo.svg" width="180" alt="Logo" style={{ marginBottom: '1rem'}}/>
        <div className="object-header">
          <div className="content">
            <h2>Object: {object.headline}</h2>
            <div className="meta">
              {object.properties.beleggers.length > 1
                ? <>Eigenaars: {object.properties.beleggers.map(belegger => belegger.name).join(', ')}</>
                : <>Eigenaar: {object.properties.beleggers[0].name}</>}
            </div>
          </div>
        </div>

        <div className=" fullwidth">
          <div className='grid gallery'>
            {media.map((file, index) => (
              <div key={`thumb-${index}`} className="item">
                <img src={file.thumb} alt={file.alt}/>
              </div>
            ))}
          </div>
        </div>
      </div>}

      {renderPages.includes('3') && <div className="page">
        <img src="/images/logo.svg" width="180" alt="Logo" style={{ marginBottom: '1rem'}}/>
        <div className="object-header">
          <div className="content">
            <h2>Object: {object.headline}</h2>
            <div className="meta">
              {object.properties.beleggers.length > 1
                ? <>Eigenaars: {object.properties.beleggers.map(belegger => belegger.name).join(', ')}</>
                : <>Eigenaar: {object.properties.beleggers[0].name}</>}
            </div>
          </div>
        </div>

        <div className=" fullwidth">
          <h3>Financieel</h3>

          <table className="table details">
            <thead>
              <tr>
                <th colspan="3">Totaal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aankoopsom</td>
                <td>{formatCurrency(object.properties.aanschafwaardepr)}</td>
                <td></td>
              </tr>
              <tr>
                <td>Taxatiewaarde</td>
                <td>{formatCurrency(object.properties.taxatiewaardepr)}</td>
                <td></td>
              </tr>
              <tr>
                <td>WOZ Waarde</td>
                <td>{formatCurrency(object.properties.wozwaarde)}</td>
                <td></td>
              </tr>
              {object.properties.geschattewaarde ? <tr>
                <td>Geschatte Waarde</td>
                <td>{formatCurrency(object.properties.geschattewaarde)}</td>
                <td></td>
              </tr> : null}
              <tr>
                <td>Hypotheek</td>
                <td>{formatCurrency(financesTotal.hypotheek)}</td>
                <td></td>
              </tr>
              <tr>
                <td>Jaarlijkse aflossing</td>
                <td>{formatCurrency(financesTotal.jaarlijkseAflossing, true)}</td>
                <td></td>
              </tr>
              <tr>
                <td>Huidig saldo</td>
                <td>{financesTotal.huidigeLening ? formatCurrency(financesTotal.huidigeLening) : 'afgelost'}</td>
                <td></td>
              </tr>
            </tbody>
          </table>

          {object.properties.finances.map((finance, index) => (<>
            <h4>{finance.bedrijf}</h4>
            <table key={`finances-${index}`} className="table details">
              <tbody>
                <tr>
                  <td>Leningnr</td>
                  <td>{finance.leningNummer}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Hypotheek</td>
                  <td>{formatCurrency(finance.hypotheek)}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Jaarlijkse aflossing</td>
                  <td>{formatCurrency(finance.jaarlijkseAflossing, true)}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Huidig saldo</td>
                  <td>{finance.huidigeLening ? formatCurrency(finance.huidigeLening) : 'afgelost'}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Begindatum lening</td>
                  <td>{formatDate(object.properties.ingangsdatum)}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Einddatum lening</td>
                  <td>{formatDate(finance.einddatumLening)}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Begindatum rente</td>
                  <td>{formatDate(object.properties.ingangsdatum)}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Einddatum rente</td>
                  <td>{formatDate(finance.einddatumRente)}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Rente</td>
                  <td>{finance.rente}%</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </>))}

          {/* <h3>Beleggersinformatie</h3>
          <table className="table details">
            <tbody>
              <tr>
                <td>Bruto rendement bij aankoop</td>
                <td></td>
              </tr>
              <tr>
                <td>Bruto rendement huidig</td>
                <td></td>
              </tr>
              <tr>
                <td>Loan to value huidig</td>
                <td></td>
              </tr>
              <tr>
                <td>Loan to value bij aankoop</td>
                <td></td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </div>}

      {renderPages.includes('4') && <div className="page">
        <img src="/images/logo.svg" width="180" alt="Logo" style={{ marginBottom: '1rem'}}/>
        <div className="object-header">
          <div className="content">
            <h2>Object: {object.headline}</h2>
            <div className="meta">
              {object.properties.beleggers.length > 1
                ? <>Eigenaars: {object.properties.beleggers.map(belegger => belegger.name).join(', ')}</>
                : <>Eigenaar: {object.properties.beleggers[0].name}</>}
            </div>
          </div>
        </div>

        <div className=" fullwidth">
          <h3>Opbrengsten uit verhuur</h3>
          <table className="table details">
            <tbody>
              {object.properties.huursomexcl ? <tr>
                <td>Huuropbrengst excl. BTW</td>
                <td>{formatCurrency(object.properties.huursomexcl)}</td>
              </tr> : null}
              {object.properties.huursom ? <tr>
                <td>Huuropbrengst incl. BTW</td>
                <td>{formatCurrency(object.properties.huursom)}</td>
              </tr> : null}
            </tbody>
          </table>

          <h3>Energielabel</h3>
          <table className="table details">
            <tbody>
              <tr>
                <td>{object.headline}</td>
                <td>{object.properties.energielabel
                  ? <Energielabel label={object.properties.energielabel}/>
                  : 'geen'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>}
  </>
}

export default ObjectPrint
