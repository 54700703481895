import { useEffect, useRef, useState } from "react"

const ToggleItem = ({ value, label, icon, tooltip }) => {
  return <div className="item with-tooltip" data-value={value}>
    {label
      ? <>
          {icon && <i className="small material-icons">{icon}</i>}
          <span className="label">{label}</span>
        </>
      : <>
          {tooltip && <span className="tooltip">{tooltip}</span>}
          <i className="small material-icons">{icon}</i>
        </>
    }
  </div>
}

const Toggle = ({ activeIndex, onChange, children }) => {
  const ref = useRef()
  const [indicatorStyle, setIndicatorStyle] = useState({})

  useEffect(() => {
    if (ref.current) {
      const items = ref.current.querySelectorAll('.item')

      if (activeIndex < items.length) {
        const active = items[activeIndex]

        if (!active) {
          setIndicatorStyle({ opacity: 0, width: ref.current.clientWidth / items.length })
        } else {
          if (active.offsetWidth > ref.current.clientWidth / items.length) {
            setTimeout(() => {
              setIndicatorStyle({
                opacity: 1,
                left: active.offsetLeft,
                width: active.offsetWidth
              })
            }, 200)
          } else {
            setIndicatorStyle({
              opacity: 1,
              left: active.offsetLeft,
              width: active.offsetWidth
            })
          }
        }
      }
    }
  }, [activeIndex])

  const onClick = (event) => {
    const target = event.target.closest('.item')
    if (target) {
      let index = -1
      for (let item = target; item && item.classList.contains('item'); item = item.previousSibling) {
        index++
      }

      if (onChange) {
        onChange(index)
      }
    }
  }

  return <div className="toggle" id="viewselect" ref={ref} onClick={onClick}>
    <div className="indicator" style={indicatorStyle}></div>
    {children}
  </div>
}

export default Toggle

export { ToggleItem }
