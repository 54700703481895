import { createContext, useEffect, useRef, useState } from "react"

const DropdownContext = createContext()

const Dropdown = ({ prelude, label, variant, panelAlign, children, onSelect, onToggle }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()

  const onClickOutside = (event) => {
    if (!ref.current?.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', onClickOutside)

    return () => {
      window.removeEventListener('click', onClickOutside)
    }
  }, [])

  const toggleOpen = () => {
    setOpen(!open)
  }

  const onSelectItem = (label) => {
    if (onSelect) {
      onSelect(label)
      setOpen(false)
    }
  }

  const onToggleItem = (label, checked) => {
    if (onToggle) {
      onToggle(label, checked)
      setOpen(false)
    }
  }

  return <DropdownContext.Provider value={{ onSelect: onSelectItem, onToggle: onToggleItem }}>
    {prelude && <span>{prelude}</span>}
    <div ref={ref} className={`dropdown${variant === 'account-button' ? ' account-button' : ''}${open ? ' open' : ''}`}>
      <span className={`label ${variant === 'account-button' ? 'outlined' : variant}`} onClick={toggleOpen}>
        {label}
        {variant === 'account-button'
          ? <img src="/images/avatar.svg" style={{ marginLeft: 5 }} alt="Avatar"/>
          : (variant === 'plain'
            ? <svg viewBox="0 0 10 10" className="dropdown-icon"><use href="#dropdown-icon" x="0" y="0"></use></svg>
            : <svg viewBox="0 0 12 12" className="dropdown-icon"><use href="#dropdown-icon-large" x="0" y="0"></use></svg> )
        }
      </span>
      <div className={`panel align-${panelAlign || 'left'}`}>
        <div className="list">
            {children}
        </div>
      </div>
    </div>
  </DropdownContext.Provider>
}

export default Dropdown
export {
  DropdownContext
}
