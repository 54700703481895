import { useState } from "react"

let filters = null

const useFilters = (initial, options) => {
  const [,setState] = useState()
  const persistent = options?.persistent

  if (!filters) {
    if (persistent) {
      const json = localStorage.getItem('useFilters')
      if (json) {
        filters = { ...initial, ...JSON.parse(json) }
      }
    }

    if (!filters) {
      filters = { ...initial }
    }
  }

  const setFilter = (filter, value) => {
    if (typeof filter === 'object') {
      for (let key in filter) {
        filters[key] = filter[key]
      }
    } else {
      filters[filter] = value
    }
    if (persistent) {
      localStorage.setItem('useFilters', JSON.stringify(filters))
    }
    setState({})
  }

  const clearFilters = (ignore) => {
    if (ignore) {
      const keep = {}
      for (let i = 0; i < ignore.length; i++) {
        if (ignore[i] in filters) {
          keep[ignore[i]] = filters[ignore[i]]
        }
      }
      filters = { ...initial, ...keep }
    } else {
      filters = { ...initial }
    }
    if (persistent) {
      localStorage.setItem('useFilters', JSON.stringify(filters))
    }
    setState({})
  }

  return { filters, setFilter, clearFilters }
}

export default useFilters
