import { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import useBulk from "../hooks/useBulk"
import Checkbox from "./Checkbox"

const ObjectsTable = ({ items, mode, page, onBulk, onClearFilters }) => {
  const bulkAllRef = useRef()

  const { bulk, bulkSpan, setChecked, setAllChecked, bulkAll, bulkAny } = useBulk(
    items && items.map instanceof Function ? items.map(i => i.id) : null,
    bulkAllRef,
    page
  )

  useEffect(() => {
    if (onBulk) {
      const ids = []
      const idsSpan = []
      for (let id in bulk) {
        if (bulk[id]) ids.push(id)
      }
      for (let id in bulkSpan) {
        if (bulkSpan[id]) idsSpan.push(id)
      }
      onBulk(bulkAny, bulkAll, ids, idsSpan)
    }
  }, [bulkAny, bulkAll, bulk])

  return <>
    <div className="flex">
      <div></div>
      <div>
        <a class="link" onClick={() => onClearFilters && onClearFilters()}>Wis alle filters</a>
      </div>
    </div>
    <table className="table">
      <thead>
        <tr>
          {mode === 'projects' && <th style={{ width: 30 }}>
            <Checkbox
              name="items"
              value="all"
              reference={bulkAllRef}
              checked={bulkAll}
              onChange={(e) => setAllChecked(e.target.checked)}
              style={{ marginBottom: 0 }}
            />
          </th>}
          <th colSpan="2">{mode === 'projects' ? 'Locatie' : 'VHE'}</th>
          <th>Eigenaar</th>
          <th>Plaats</th>
          {mode === 'objects' && <th>Huurder</th>}
        </tr>
      </thead>
      <tbody>
        {items?.map(item => {
          return <tr key={item.id}>
            {mode === 'projects' && <td style={{ width: 30, verticalAlign: 'middle' }}>
              <Checkbox
                name="items"
                value={item.id}
                id={`items_${item.id}`}
                checked={!!bulk[item.id]}
                onChange={(e) => setChecked(item.id, e.target.checked)}
                style={{ marginBottom: 0 }}
              />
            </td>}
            <td className="narrow column no-pad-right">
              <div className="crop-image square rounded" style={{ backgroundImage: `url(${item.media.length > 0 ? item.media[0].thumb : '/images/noimage.png'})`, width: 32, borderRadius: 5 }}></div>
            </td>
            <td><Link to={`/${mode}/${item.id}`} className="link">{item.headline}</Link></td>
            <td>{item.properties.beleggers.filter(b => !!b.name).map(b => b.name).join(', ')}</td>
            <td>{item.properties.plaats}</td>
            {mode === 'objects' && <td>{item.properties.huurder}</td>}
          </tr>
        })}
      </tbody>
    </table>
  </>
}

export default ObjectsTable
