import { useEffect, useRef, useState } from "react"

const useBulk = (ids, bulkAllRef, page) => {
  const bulkPages = useRef({})
  page = page || 1

  if (!bulkPages.current[page]) {
    bulkPages.current[page] = {}
  }

  const [bulk, setBulk] = useState({})
  const [bulkSpan, setBulkSpan] = useState({})

  useEffect(() => {
    setBulk(bulkPages.current[page])
  }, [page])

  let all = true
  let any = false

  const rebuild = () => {
    const _bulk = Object.keys(bulkPages.current).reduce((prev, page) => {
      return { ...prev, ...bulkPages.current[page] }
    }, {})
    setBulkSpan(_bulk)
  }

  const setChecked = (id, checked) => {
    bulk[id] = checked
    bulkSpan[id] = checked
    bulkPages.current[page][id] = checked
    setBulk({...bulk})
    setBulkSpan({...bulkSpan})
  }

  const setAllChecked = (checked) => {
    if (!checked) {
      bulkPages.current[page] = {}
      setBulk({})
      rebuild()
    } else {
      const _bulk = {}
      ids?.forEach(id => {
        _bulk[id] = true
        bulkPages.current[page][id] = true
      })
      setBulk(_bulk)
      rebuild()
    }
  }

  if (!ids || ids.count === 0) {
    all = false
  } else {
    ids?.forEach(id => {
      any = any || !!bulk[id]
      all = all && !!bulk[id]
    })
  }

  if (bulkAllRef?.current) {
    bulkAllRef.current.indeterminate = !all && any
  }

  return { bulk, bulkSpan, setChecked, setAllChecked, bulkAll: all, bulkAny: any }
}

export default useBulk
