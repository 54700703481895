import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { Routes, Route } from 'react-router'
import './App.css'
import Login from './pages/auth/Login'
import Objects from './pages/Objects'
import ObjectDetail from './pages/ObjectDetail'
import ProjectDetail from './pages/ProjectDetail'
import ObjectPrint from './pages/ObjectPrint'
import ProjectPrint from './pages/ProjectPrint'
import Logout from './pages/auth/Logout'

const queryClient = new QueryClient()

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className="App">
          <svg style={{ display: 'none'}}>
            <defs>
              <symbol id="dropdown-icon"><path d="M2 3l3 3l3-3" fill="none" strokeWidth="2" /></symbol>
              <symbol id="dropdown-icon-large"><path d="M2 4l4 4l4-4" fill="none" strokeWidth="1.5" /></symbol>
            </defs>
          </svg>


          <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/logout" element={<Logout/>}/>
            <Route path="/" element={<Objects/>}/>
            <Route path="/objects/:id/print/:pages" element={<ObjectPrint/>}/>
            <Route path="/objects/:id" element={<ObjectDetail/>}/>
            <Route path="/projects/:id/print/:pages" element={<ProjectPrint/>}/>
            <Route path="/projects/:id" element={<ProjectDetail/>}/>
          </Routes>
        </div>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false}/>
    </QueryClientProvider>
  );
}

export default App;
