import config from "../../config"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router"
// import authClient from "../../clients/authClient"
// import Auth from "../../layouts/Auth"

const Login = () => {
  // const [username, setUsername] = useState('')
  // const [password, setPassword] = useState('')
  // const [errors, setErrors] = useState({})
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const m = location.search?.match(/(\?|&)sid=([^&]+)/)
    if (m) {
      const sessionId = m[2]

      if(sessionId == '{sid}'){
        console.error('login failed')
        navigate('/login')
      }
      else{
        localStorage.setItem('AddSessionId', sessionId)
        navigate('/')
      }
    } else {
      window.location = `${config.server.authUri}/check-login?referer=${encodeURIComponent(window.location.toString())}`
    }
  }, [])

  return <>Redirecting...</>

  // const onSubmit = async (event) => {
  //   event.preventDefault()

  //   const _errors = {}
  //   let valid = true

  //   if (!username) {
  //     _errors.username = 'Gebruikersnaam is verplicht'
  //     valid = false
  //   }
  //   if (!password) {
  //     _errors.password = 'Wachtwoord is verplicht'
  //     valid = false
  //   }

  //   if (valid) {
  //     // Login
  //     const response = await authClient.login(username, password)
  //     if (response.success) {
  //       navigate('/')
  //     } else {
  //       _errors.general = response.error
  //       setErrors(_errors)
  //     }
  //   } else {
  //     setErrors(_errors)
  //   }
  // }

  // return <Auth>
  //   <div className="auth-header">
  //     <h4>Log in bij REND</h4>
  //     <p>Leg in met uw gebruikersnaam en wachtwoord</p>
  //   </div>

  //   {errors.general &&
  //     <div className="alert danger">
  //       {errors.general}
  //     </div>
  //   }

  //   <form className="form" onSubmit={onSubmit}>
  //     <div className={`field${errors.username ? ' error' : ''}`}>
  //       <label for="login-username">Gebruikersnaam</label>
  //       <input type="text" id="login-username" value={username} onChange={e => setUsername(e.target.value)}/>
  //       {errors.username && <div class="field-hint">{errors.username}</div>}
  //     </div>

  //     <div className={`field${errors.password ? ' error' : ''}`}>
  //       <div className="label-hint">
  //         <a href="/">Wachtwoord vergeten?</a>
  //       </div>
  //       <label for="login-password">Wachtwoord</label>
  //       <input type="password" id="login-password" value={password} onChange={e => setPassword(e.target.value)}/>
  //       {errors.password && <div class="field-hint">{errors.password}</div>}
  //     </div>

  //     <button className="button fullwidth solid blue" type="submit">
  //       Log in
  //     </button>
  //   </form>
  // </Auth>
}

export default Login
