const Filterbar = ({ children }) => {

  return <div className="filterbar">
    <div className="container">
      {children}
    </div>
  </div>
}

export default Filterbar
