const periods = {
  y: 'jaar',
  m: 'maand'
}

const numbers = [ 'zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten' ]

const formatDate = (dateString) => {
  if (!dateString || dateString === '-') return ''

  const date = new Date(dateString)
  return date.toLocaleDateString('nl', { day: '2-digit', month: '2-digit', year: 'numeric' })
}

const formatCurrency = (amount, formatZero) => {
  if (!amount || amount === '-') {
    if (!formatZero) {
      return ''
    } else {
      amount = 0
    }
  }

  amount = parseFloat(amount)
  return amount.toLocaleString('nl', {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

const formatNumber = (amount, digits) => {
  if (!amount || amount === '-') return ''
  amount = parseFloat(amount)
  return amount.toLocaleString('nl', {
    maximumFractionDigits: digits,
  })
}

const formatLooptijd = (looptijd) => {
  if (!looptijd || looptijd === '-') return ''
  const what = looptijd[0]
  const amount = looptijd.substring(1)
  if (parseInt(amount) <= 0) return ''

  const period = periods[what]

  return `${amount} ${period}`
}

const numberToText = (number) => {
  return numbers[number]
}

export {
  formatCurrency,
  formatNumber,
  formatDate,
  formatLooptijd,
  numberToText
}
