import { useEffect, useState } from "react"

const useMediaQuery = (query) => {
  const mql = window.matchMedia(query)
  const [matches, setMatches] = useState(mql.matches)

  useEffect(() => {
    const listener = () => {
      setMatches(mql.matches)
    }

    mql.addEventListener('change', listener)

    return () => { mql.removeEventListener('change', listener) }
  }, [])

  return matches
}

export default useMediaQuery
