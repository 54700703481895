const serverBaseUri = process.env.NODE_ENV === 'development'
  ? 'http://rend.local'
  : 'https://www.rendapp.nl/'

const config = {
  server: {
    baseUri: `${serverBaseUri}/object/rend`,
    authUri: `${serverBaseUri}/user/auth`,
  }
}

export default config
