const colors = {
  A: '#006634',
  B: '#018C37',
  C: '#92C01F',
  D: '#FFEB02',
  E: '#F7B230',
  F: '#E95A0C',
  G: '#BC2421'
}

const Energielabel = ({ label }) => {
  if (!label.match(/^[A-Z]\+*$/)) {
    return <>{label}</>
  }

  const colorLabel = label?.replace(/\++/g, '')

  return <svg
    width={43}
    height={24}
    viewBox="0 0 43 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h29l12 12l-12 12h-29z" fill={colors[colorLabel]||colors.G}/>
    <text x={9} y={17} fill="#fff" style={{ font: "14px 'Avenir Next', sans-serif", fontWeight: 500 }}>{label}</text>
  </svg>
}

export default Energielabel
