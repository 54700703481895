import { Link } from "react-router-dom"

const Card = ({ image, title, meta, linkTo, extra }) => {
  return <div className="item">
    <Link className="card" to={linkTo}>
      <div className="image">
        <div className="crop-image rounded square" style={{ backgroundImage: `url(${image ? image : '/images/noimage.png'})` }}></div>
        {extra && <div className="extra">{extra}</div>}
      </div>
      <div className="content">
        {/* <div className="right">
          <span className="icon button with-tooltip hover-action">
            <span className="tooltip">Bewerken</span>
            <i className="small material-icons">edit</i>
          </span>
        </div> */}
        <div className="title">{title}</div>
        {meta && <div className="meta">{meta}</div>}
      </div>
    </Link>
  </div>
}

export default Card
