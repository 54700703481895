import Filterbar from "../components/Filterbar"
import FilterbarPart from "../components/FilterbarPart"
import Toggle, { ToggleItem } from "../components/Toggle"
import objectsClient from "../clients/objectsClient"
import { useQuery } from "react-query"
import Frontend from "../layouts/Frontend"
import Pagination from "../components/Pagination"
import projectsClient from "../clients/projectsClient"
import useFilters from "../hooks/useFilters"
import { useNavigate } from "react-router"
import { useEffect, useState } from "react"
import useMediaQuery from "../hooks/useMediaQuery"
import ObjectsTable from "../components/ObjectsTable"
import ObjectsListView from "../components/ObjectsListView"
import ObjectsGallery from "../components/ObjectsGallery"
import Modal from "../components/Modal"
import ProjectSummary from "./ProjectSummary"
import OptionsDropdown from "../components/OptionsDropdown"

const shareOptions = {
  part: 'Alle locaties',
  full: 'Volledig'
}

const Objects = () => {
  const navigate = useNavigate()
  const [filterPanelOpen, setFilterPanelOpen] = useState()
  const isDesktop= useMediaQuery('(min-width: 600px)')
  const [actionbarVisible, setActionbarVisible] = useState(false)
  const [bulkIds, setBulkIds] = useState(null)
  const [summaryIds, setSummaryIds] = useState(null)
  const [summaryModalOpen, setSummaryModalOpen] = useState(false)
  const [shareDropdownOptions, setShareDropdownOptions] = useState(shareOptions)
  const [bulkAll, setBulkAll] = useState(false)
  const mode = 'projects'

  const { filters, setFilter, clearFilters } = useFilters({
    search: '',
    plaats: null,
    belegger: null,
    share: [ 'part' ],
    page: 1,
    viewIndex: 0
  }, { persistent: true })

  const { page, viewIndex, plaats, belegger, share, search } = filters

  const setShareFilter = (value) => {
    if (value instanceof Array) {
      const _value = [ ...value ]
      if(_value.length === 0){
        _value.push('part');
      }

      if(_value.length > 1){
        /* als value 'full' bevat, alles behalve full weghalen?? */
        let index = _value.indexOf('full')
        if (index === 0) {
          _value.splice(0, 1)
        } else if (index > 0) {
          _value.splice(0, _value.length - 1)
        }

        if(index === -1){
          /* als value 'part' bevat, alles behalve full weghalen?? */
          index = _value.indexOf('part')

          if (index === 0) {
            _value.splice(0, 1)
          } else if (index > 0) {
            _value.splice(0, _value.length - 1)
          }
        }
      }

      setFilter({ share: _value, page: 1 })
    } else {
      setFilter({ share: [ value ], page: 1 })
    }
  }

  useEffect(() => {
    document.title = `Overzicht ${mode === 'objects' ? 'VHE\'s' : 'locaties'} | REND`
  }, [mode])

  const { isLoading, data: items } = useQuery(
    mode,
    mode === 'objects' ? objectsClient.all : projectsClient.all
  )

  const singular = mode === 'objects' ? 'VHE' : 'project'
  const plural = mode === 'objects' ? 'VHE\'s' : 'locaties'

  const plaatsOptions = []
  const beleggerOptions = []
  let filteredItems = []

  const onBulk = (any, all, ids, idsSpan) => {
    setBulkIds([...idsSpan])
    setActionbarVisible(any)
    setBulkAll(all)
  }

  const onClearFilters = () => {
    clearFilters([ 'viewIndex' ])
  }

  const setBeleggerOptions = (belegger) => {
    if (belegger && items && items instanceof Array) {
      // const filtered = items.filter(item => item.properties.beleggers.findIndex(b => b.name === belegger) >= 0)
      const options = { ...shareOptions }

      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.properties.beleggers.findIndex(b => b.name === belegger) >= 0) {
          for (let j = 0; j < item.properties.beleggers.length; j++) {
            const b = item.properties.beleggers[j]
            if (b.name !== belegger && !(b.name in options)) {
              options[b.name] = b.name
            }
          }
        }
      }

      setShareDropdownOptions(options)

    } else {
      setShareDropdownOptions(shareOptions)
    }
  }

  useEffect(() => {
    setShareFilter('part')
    setBeleggerOptions(belegger)
  }, [belegger,items])

  if (items?.error === 'Not logged in') {
    navigate('/login')
    return null
  }

  if (!items?.error) {
    items?.forEach(item => {
      if (item.properties.plaats && !plaatsOptions.includes(item.properties.plaats)) {
        plaatsOptions.push(item.properties.plaats)
      }
      if (item.properties.beleggers) {
        item.properties.beleggers.forEach(belegger => {
          if (!beleggerOptions.includes(belegger.name)) {
            beleggerOptions.push(belegger.name)
          }
        })
      }
    })

    plaatsOptions.sort()
    beleggerOptions.sort()

    filteredItems = items && (belegger || plaats || search)
      ? items.filter(item => {
          if (belegger) {
            const found = item.properties.beleggers.find(b => b.name === belegger)
            if (!found) return false
            if (share.indexOf('full') >= 0) {
              if (item.properties.beleggers.length > 1) {
                return false
              }
            } else if (share.indexOf('part') < 0) {
              if (item.properties.beleggers.findIndex(b => share instanceof Array ? share.indexOf(b.name) >= 0 : b.name === share) < 0) {
                return false
              }
            }
          }
          if (plaats && item.properties.plaats !== plaats) {
            return false
          }
          if (search) {
            if (item.headline.toLowerCase().indexOf(search.toLowerCase()) < 0) {
              return false
            }
          }
          return true
        })
      : items
  }

  const showSummary = () => {
    setSummaryIds(bulkIds)
    setSummaryModalOpen(true)
  }

  const showSummaryAll = () => {
    if (filteredItems) {
      setSummaryIds(filteredItems.map(item => item.id))
      // setSummaryIds('all')
      setSummaryModalOpen(true)
    }
  }

  const pagedItems = filteredItems?.slice((page - 1) * 20, page * 20)

  return <Frontend>
    <Filterbar>
      <FilterbarPart where="left">
        <div className="searchbar">
          <input
            type="search"
            placeholder={`Zoek een ${mode === 'projects' ? 'locatie' : 'VHE'}`}
            value={search}
            onChange={e => setFilter({ search: e.target.value, page: 1 })}
          />
          <i className="material-icons">search</i>
        </div>
      </FilterbarPart>
      <FilterbarPart where="right">
        <div className="desktop-only">
          <OptionsDropdown
            label='Plaats'
            value={plaats}
            options={plaatsOptions}
            allLabel='Alle plaatsen'
            onChange={value => setFilter({ plaats: value === 'Alle plaatsen' ? null : value, page: 1 })}
          />
          {beleggerOptions.length > 0 ?
            <OptionsDropdown
              label='Belegger'
              value={belegger}
              options={beleggerOptions}
              allLabel='Alle beleggers'
              onChange={value => setFilter({ belegger: value === 'Alle beleggers' ? null : value, page: 1 })}
            /> : null}
          {belegger ?
            <OptionsDropdown label='Eigenaar'
                             value={share}
                             options={shareDropdownOptions}
                             onChange={value => setShareFilter(value)}
                             multiple/>
          : null}

          <Toggle activeIndex={viewIndex} onChange={index => setFilter('viewIndex', index)}>
            <ToggleItem value="tiles" icon="grid_view" tooltip="Tonen als tegels"/>
            <ToggleItem value="list" icon="list" tooltip="Tonen als lijst"/>
          </Toggle>
        </div>

        <button onClick={() => setFilterPanelOpen(true)} className="button outlined mobile-only">
          <i className="material-icons"
              style={{ fontSize: 18,
                       verticalAlign: 'middle',
                       position: 'relative',
                       top: -2,
                       marginRight: 2}}>
            filter_alt
          </i>
          Filters
        </button>
      </FilterbarPart>
    </Filterbar>

    {filterPanelOpen ? <div className="filterpanel">
      <button
        className="modal-close"
        title="Sluiten"
        onClick={() => setFilterPanelOpen(false)}
      >
        <i className="material-icons">clear</i>
      </button>
      <div className="filterpanel-content">
        <div className="filterpanel-section">
          <div className="filterpanel-label">Tonen als</div>
          <Toggle activeIndex={viewIndex} onChange={index => setFilter('viewIndex', index)}>
            <ToggleItem value="tiles" icon="grid_view" label="Tegels"/>
            <ToggleItem value="list" icon="list" label="Lijst"/>
          </Toggle>
        </div>

        <div className="filterpanel-section">
          <div className="filterpanel-label">Plaats</div>
          <div className="checklist">
            <div
              onClick={() => setFilter('plaats', null)}
              className={`checklist-item${!plaats ? ' selected' : ''}`}
              key={`plaats-all`}
            >
              Alle plaatsen
            </div>
            {plaatsOptions.map((plaats, index) => (
              <div
                onClick={() => setFilter('plaats', plaats)}
                className={`checklist-item${plaats === filters.plaats ? ' selected' : ''}`}
                key={`plaats-${index}`}
              >
                {plaats}
              </div>
            ))}
          </div>
        </div>

        {beleggerOptions.length > 2 ? <div className="filterpanel-section">
          <div className="filterpanel-label">Belegger</div>
          <div className="checklist">
            <div onClick={() => setFilter('belegger', null)}
                 className={`checklist-item${!belegger ? ' selected' : ''}`}
                 key={`belegger-all`} >
              Alle beleggers
            </div>
            {beleggerOptions.map((belegger, index) => (
              <div onClick={() => setFilter('belegger', belegger)}
                   className={`checklist-item${belegger === filters.belegger ? ' selected' : ''}`}
                   key={`belegger-${index}`}>
                {belegger}
              </div>
            ))}
          </div>
        </div> : null}

        {belegger ? <div className="filterpanel-section">
          <div className="filterpanel-label">Eigenaar</div>
            <div className="checklist">
{/*              <div onClick={() => setShareFilter(null)}
                   className={`checklist-item${!share ? ' selected' : ''}`}
                   key={`share-all`} >
                Alle eigenaren
              </div>*/}
              {Object.keys(shareDropdownOptions).map((share, index) => (

                <div onClick={() => setShareFilter(share)}
                     className={`checklist-item${filters.share.indexOf(share) > -1 ? ' selected' : ''}`}
                     key={`share-${index}`}>
                     {console.log(filters)}
                  {shareDropdownOptions[share]}
                </div>
              ))}
            </div>
          </div>
          : null}
      </div>
      <div className="filterpanel-actions">
        <button className="filterpanel-button filterpanel-confirm" onClick={() => setFilterPanelOpen(false)}>
          Toon {filteredItems.length === 1 ? '1 ' + singular : filteredItems.length + ' ' + plural}
        </button>
      </div>
    </div> : null}


    <div className="container">
      {isLoading && <div className="loader"></div>}
      {!isLoading && (!items || items?.error) && <div className="noresults">{items?.error || 'Er is een onbekende fout opgetreden'}</div>}

      {!isLoading && items && !items?.error && items.length === 0 && <div className="noresults">Er zijn geen {mode === 'objects' ? 'VHE\'s' : 'locaties'} gevonden</div>}

      {!isLoading && !items?.error && <>
        {viewIndex === 0
          ? <ObjectsGallery items={pagedItems} mode={mode} page={page} onBulk={onBulk} onClearFilters={onClearFilters} />
          : (isDesktop
              ? <ObjectsTable items={pagedItems} mode={mode} page={page} onBulk={onBulk} onClearFilters={onClearFilters} />
              : <ObjectsListView items={pagedItems} mode={mode} page={page} onBulk={onBulk} onClearFilters={onClearFilters} />
            )
        }

        <Pagination current={page} itemCount={filteredItems?.length} pageSize={20} onChange={page => setFilter('page', page)} max={5} />

        <div className={`actionbar${actionbarVisible ? ' visible' : ''}`}>
          <div className="container">
            <button className="button outlined" onClick={showSummary}>Overzicht van geselecteerde locaties</button>
            {bulkAll && <button className="button outlined" onClick={showSummaryAll}>Overzicht van alle locaties</button>}
          </div>
        </div>
      </>}
    </div>

    <Modal
      open={summaryModalOpen}
      onClose={() => setSummaryModalOpen(false)}
    >
      {summaryModalOpen && <ProjectSummary ids={summaryIds}/>}
    </Modal>
  </Frontend>
}

export default Objects
