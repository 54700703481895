import { useQuery } from "react-query"
import projectsClient from "../clients/projectsClient"
import config from "../config"
import { formatCurrency, formatNumber } from "../functions"

const ProjectSummary = ({ ids }) => {
  const key = ids instanceof Array ? ids.join('-') : ids
  const { isLoading, data } = useQuery([ 'project-summary', key ], () => projectsClient.summary(ids))

  return <div className="content-column fullwidth">
    {isLoading
      ? <>Bezig met laden...</>
      : <>
          <h2 style={{marginTop: 0}}>Overzicht {data?.projects?.length || 0} {data?.projects?.length === 1 ? 'locatie' : 'locaties'}</h2>
          <ul>
            {data?.projects?.slice(0, 5).map(project => {
              return <li key={project.id}>{project.headline}</li>
            })}
            {data?.projects?.length > 5 &&
              <li>En {data.projects.length - 5} meer</li>
            }
          </ul>
          <h3>Totalen</h3>
          <table className="table details">
            <tbody>
              <tr>
                <td>Totale huuropbrengst</td>
                <td>{formatCurrency(data?.summary?.huursom)}</td>
              </tr>
              <tr>
                <td>Totale huuropbrengst excl. BTW</td>
                <td>{formatCurrency(data?.summary?.huursomExcl)}</td>
              </tr>
              <tr>
                <td>Totale financiering</td>
                <td>{data?.summary?.financiering ? formatCurrency(data?.summary?.financiering) : 'afgelost'}</td>
              </tr>
              <tr>
                <td>Totale taxatiewaarde</td>
                <td>{formatCurrency(data?.summary?.taxatiewaarde, true)}</td>
              </tr>
              <tr>
                <td>Totale loan to value</td>
                <td>{data?.summary?.loanToValue ? formatNumber(data?.summary?.loanToValue, 2) + ' %' : 'afgelost'}</td>
              </tr>
            </tbody>
          </table>

          <a href={`${config.server.baseUri}/project-summary?ids${ids instanceof Array ? `[]=${ids.join('&ids[]=')}` : `=${ids}`}&format=pdf`} target="_blank" rel="noreferrer" className="button solid blue">Download PDF</a>
        </>
    }
  </div>
}

export default ProjectSummary
