import { useEffect } from "react"

const Modal = ({ open, onClose, fullscreen, children }) => {
  useEffect(() => {
    if (open) {
      document.documentElement.classList.add('modal-open')
    } else {
      document.documentElement.classList.remove('modal-open')
    }
  }, [open])

  if (!open) {
    return null
  }

  return <>
    {!fullscreen && <div className="modal-dimmer"></div>}
    <div className={`modal-container${fullscreen ? ' modal-container-fullscreen' : ''}`}>
      <div className="modal">
        {onClose && <button className="modal-close" title="Sluiten" onClick={onClose}><i className="material-icons">clear</i></button>}
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  </>
}

export default Modal
