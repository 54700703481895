const Pagination = ({ current, itemCount, pageSize, onChange, max }) => {
  const pageCount = Math.ceil(itemCount / pageSize)
  const pages = []
  const currentPage = parseInt(current)

  if (max) {
    let leftBound = Math.max(1, currentPage - Math.floor(max / 2))
    const rightBound = Math.min(leftBound + max - 1, pageCount)

    if (rightBound === pageCount && 1 + rightBound - leftBound < max) {
      leftBound = Math.max(1, 1 + rightBound - max)
    }

    if (leftBound > 3) {
      pages.push(1)
      pages.push('...')
    } else {
      for (let i = 1; i < leftBound; i++) {
        pages.push(i)
      }
    }

    for (let i = leftBound; i <= rightBound; i++) {
      pages.push(i)
    }

    if (rightBound < pageCount - 2) {
      pages.push('...')
      pages.push(pageCount)
    } else {
      for (let i = rightBound + 1; i <= pageCount; i++) {
        pages.push(i)
      }
    }

  } else {
    for (let i = 1; i <= pageCount; i++) {
      pages.push(i)
    }
  }

  if (pageCount <= 1) {
    return null
  }

  return <ul className="pagination">
    {pages.map((page, index) => (
      <li key={`page-${index}`} className={`pagination-page${page === currentPage ? ' current' : ''}`}>
        {page === '...'
          ? <span className="dots">...</span>
          : <span onClick={event => event.button === 0 && onChange && onChange(page)}>{page}</span>
        }
      </li>
    ))}
  </ul>
}

export default Pagination
