import Dropdown from "./Dropdown"
import DropdownItem from "./DropdownItem"
import DropdownItemCheckbox from "./DropdownItemCheckbox"

const OptionsDropdown = ({ label, value, options, allLabel, onChange, multiple }) => {
  let valueLabel = null
  if (value instanceof Array && value.length > 0) {
    valueLabel = value.length === 1 ? options[value[0]] : 'Meeredere'
  } else if (value) {
    valueLabel = options instanceof Array ? value : options[value]
  }

  const onToggle = (val, checked) => {
    if (!onChange) return

    const _value = value instanceof Array ? [ ...value ] : [ value ]
    const index = _value.indexOf(val)

    if (checked) {
      if (index < 0) {
        _value.push(val)
        onChange(_value)
      }
    } else {
      if (index >= 0) {
        _value.splice(index, 1)
        onChange(_value)
      }
    }
  }

  const Component = multiple ? DropdownItemCheckbox : DropdownItem

  return <Dropdown
    variant="outlined"
    label={<><span>{label}</span> {value ? ': ' + valueLabel : ''}</>}
    onSelect={onChange}
    onToggle={onToggle}
  >
    {allLabel && <Component key={`option-all`} label={allLabel} selected={!value}/>}
    {options instanceof Array
      ? options.map((val, index) => (
          <Component
            key={`plaats-${index}`}
            label={val}
            selected={multiple && value instanceof Array ? value.indexOf(val) >= 0 : val === value}
          />
        ))
      : Object.keys(options).map((val, index) => (
          <Component
            key={`plaats-${index}`}
            value={val}
            label={options[val]}
            selected={multiple && value instanceof Array ? value.indexOf(val) >= 0 : val === value}
          />
        ))
    }
  </Dropdown>
}

export default OptionsDropdown
