import { useContext } from "react"
import Checkbox from "./Checkbox"
import { DropdownContext } from "./Dropdown"

const DropdownItemCheckbox = ({ label, value, selected }) => {
  const { onToggle } = useContext(DropdownContext)

  const className = `item${selected ? ' active' : ''}`

  return <label className={className}>
    <Checkbox name='' value={value === undefined ? value : label} checked={selected} onChange={(e) => {
      if(onToggle){
        let toggle_value = (value === undefined ? label : value)

        console.log(value, label, toggle_value, e.target.checked)
        return onToggle(toggle_value, e.target.checked);
      }
    }}/>
    <span>{label}</span>
  </label>
}

export default DropdownItemCheckbox
