import config from "../../config"
import { useEffect } from "react"

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem('AddSessionId')
    window.location = `${config.server.authUri}/logout?referer=${encodeURIComponent(window.location.toString().replace('/logout', '/login'))}`
  }, [])

  return <>Redirecting...</>
}

export default Logout
