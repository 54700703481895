import authFetch from '../authFetch'
import config from '../config'

const projectsClient = {
  all: async () => {
    const result = await authFetch(`${config.server.baseUri}/projects`)
    return result
  },
  one: async (id) => {
    const result = await authFetch(`${config.server.baseUri}/project/id/${id}`)
    return result
  },
  summary: async (ids) => {
    const result = await authFetch(`${config.server.baseUri}/project-summary?ids${ids instanceof Array ? `[]=${ids.join('&ids[]=')}` : `=${ids}`}`)
    return result
  }
}

export default projectsClient
