import { useContext } from "react"
import Checkbox from "./Checkbox"
import { DropdownContext } from "./Dropdown"

const DropdownItem = ({ label, value, href, selected, checkbox }) => {
  const { onSelect } = useContext(DropdownContext)

  const className = `item${selected ? ' active' : ''}`

  if (href) {
    return <a className={className} href={href}>{label}</a>
  }
  return <div
      className={className}
      onClick={() => onSelect && onSelect(value === undefined ? label : value)}
    >
      {checkbox ? <Checkbox checked={selected}/> : null}
      {label}
    </div>
}

export default DropdownItem
