import useUser from "../hooks/useUser"
import Dropdown from "./Dropdown"
import DropdownItem from "./DropdownItem"

const Topbar = () => {
  const user = useUser()

  return <div className="topbar">
    <div className="container">
      <a href="/" className="brand">
        <img src="/images/logo.svg" alt="REND"/>
      </a>
      <div className="right">
        <Dropdown
          variant="account-button"
          label={user ? (user.name?.length > 20 ? user.name.substring(0, 19) + '...' : user.name) : 'Not logged in'}
          panelAlign='right'
        >
          <DropdownItem href="/logout" label="Uitloggen"/>
        </Dropdown>
      </div>
    </div>
  </div>
}

export default Topbar
