import authFetch from '../authFetch'
import config from '../config'

const objectsClient = {
  all: async () => {
    const result = await authFetch(`${config.server.baseUri}/objects`)
    return result
  },
  one: async (id) => {
    const result = await authFetch(`${config.server.baseUri}/object/id/${id}`)
    return result
  }
}

export default objectsClient
