const authFetch = async (url, options) => {
  options = options || {}
  const sessionId = localStorage.getItem('AddSessionId')

  if (sessionId) {
    if (!options.headers) {
      options.headers = {}
    }
    options.headers.Authorization = `Bearer ${sessionId}`
    options.credentials = 'include'
  }

  // const method = options.method?.toLowerCase()
  if (options.body && typeof options.body === 'object') {
    if (!options.headers) {
      options.headers = {}
    }
    options.headers['Content-Type'] = 'application/json'
    options.body = JSON.stringify(options.body)
  }

  const response = await fetch(url, options)
  const json = await response.json()

  return json
}

export default authFetch
