import { createContext, useContext, useState, useRef, useEffect } from 'react'

const AccordionContext = createContext()

const Accordion = ({ children, multiExpand }) => {
  const [open, setOpen] = useState([])

  const toggle = (ref) => {
    let _open = [ ...open ]
    const index = _open.indexOf(ref)
    if (index >= 0) {
      _open.splice(index, 1)
    } else {
      if (multiExpand) {
        _open.push(ref)
      } else {
        _open = [ ref ]
      }
    }
    setOpen(_open)
  }

  const isOpen = (ref) => {
    return open.indexOf(ref) >= 0
  }

  return <div className="accordion">
    <AccordionContext.Provider value={{ isOpen, toggle }}>
      {children}
    </AccordionContext.Provider>
  </div>
}

const AccordionItem = ({ children }) => {
  const ref = useRef()
  const { isOpen, toggle } = useContext(AccordionContext)
  const open = isOpen(ref.current)

  useEffect(() => {
    if (ref.current) {
      const details = ref.current.querySelector('.accordion-details')
      if (details) {
        if (open) {
          ref.current.classList.add('open')
          details.style.transition = 'height .3s'
          details.style.height = details.scrollHeight + 'px'
          setTimeout(() => {
            details.style.transition = 'none'
            details.style.height = 'auto'
          }, 400)
        } else {
          ref.current.classList.remove('open')
          details.style.height = details.scrollHeight + 'px'
          details.style.transition = 'height .3s'
          setTimeout(() => {
            details.style.height = 0
            setTimeout(() => {
              details.style.transition = 'none'
            }, 400)
          })
        }
      }
    }
  }, [open])

  const onClick = (event) => {
    if (event.target.closest('.accordion-summary')) {
      event.preventDefault()
      toggle(ref.current)
    }
  }

  return <div ref={ref} className={`accordion-item`} onClick={onClick}>
    {children}
  </div>
}

const AccordionSummary = ({ children }) => {
  return <div className="accordion-summary">
    {children}
  </div>
}

const AccordionDetails = ({ children }) => {
  return <div className="accordion-details">
    {children}
  </div>
}

export {
  Accordion,
  AccordionItem,
  AccordionSummary,
  AccordionDetails
}
