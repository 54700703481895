import { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import useBulk from "../hooks/useBulk"
import Checkbox from "./Checkbox"

const ObjectsListView = ({ items, mode, page, onBulk, onClearFilters }) => {
  const bulkAllRef = useRef()

  const { bulk, bulkSpan, setChecked, setAllChecked, bulkAll, bulkAny } = useBulk(
    items && items.map instanceof Function ? items.map(i => i.id) : null,
    bulkAllRef,
    page
  )

  useEffect(() => {
    if (onBulk) {
      const ids = []
      const idsSpan = []
      for (let id in bulk) {
        if (bulk[id]) ids.push(id)
      }
      for (let id in bulkSpan) {
        if (bulkSpan[id]) idsSpan.push(id)
      }
      onBulk(bulkAny, bulkAll, ids, idsSpan)
    }
  }, [bulkAny, bulkAll, bulk])

  return <>
    <div class="flex">
      <div>
        {mode === 'projects' && <Checkbox
          label="Selecteer alles"
          name="items"
          value="all"
          reference={bulkAllRef}
          checked={bulkAll}
          onChange={(e) => setAllChecked(e.target.checked)}
          style={{ fontSize: 12 }}
        />}
      </div>
      <div>
        <a class="link" onClick={() => onClearFilters && onClearFilters()}>Wis alle filters</a>
      </div>
    </div>
    <div className="listview border-top">
      {items?.map(item => {
        const beleggers = item.properties.beleggers.filter(b => !!b.name).map(b => b.name)

        return <div className="listview-item">
            <div className="image">
              <div className="crop-image square rounded" style={{ backgroundImage: `url(${item.media.length > 0 ? item.media[0].thumb : '/images/noimage.png'})`, width: 48, borderRadius: 10 }}>
                {mode === 'projects' && <div className="extra">
                  <Checkbox
                    name="items"
                    value={item.id}
                    checked={!!bulk[item.id]}
                    onChange={(e) => setChecked(item.id, e.target.checked)}
                    style={{ marginBottom: 0 }}
                  />
                </div>}
              </div>
            </div>
            <div className="content">
              <div className="title"><Link to={`/${mode}/${item.id}`} className="link">{item.headline}</Link></div>
              <div className="subtitle">{item.properties.plaats}</div>
              <div className="meta">{beleggers.length === 1 ? 'Belegger: ' : 'Beleggers: '}{beleggers.join(', ')}</div>
              {item.properties.huurder && <div className="meta">Huurder: {item.properties.huurder}</div>}
            </div>
          </div>
      })}
    </div>
  </>
}

export default ObjectsListView
