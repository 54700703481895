import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"
import projectsClient from "../clients/projectsClient"
import { Accordion, AccordionDetails, AccordionItem, AccordionSummary } from "../components/Accordion"
import Modal from "../components/Modal"
import Frontend from "../layouts/Frontend"
import { formatCurrency, formatDate, formatNumber, numberToText } from "../functions"
import Pandgegevens from "../components/Pandgegevens"
import Energielabel from "../components/Energielabel"
import config from "../config"
import useMediaQuery from "../hooks/useMediaQuery"

const fieldsPandgegevens = {
  beleggers: {
    label: 'Eigenaar',
    format: value => value.map(b => b.name).join(', ')
  },
  headline: {
    label: 'Adres'
  },
  postcode: {
    label: 'Postcode',
  },
  extra: {
    label: 'Extra informatie'
  },
  locatie: {
    custom: object => <a className="link" rel="noreferrer" href={`https://www.google.com/maps/place/${encodeURIComponent(object.properties.adres + ' ' + object.properties.nummer + ', ' + object.properties.postcode + ' ' + object.properties.plaats).replace(/%20/g, '+').replace(/%2C/g, ',')}`} target="_blank">Bekijk op de kaart</a>
  }
}

const printablePages = {
  '1': 'Foto\'s',
  '2': 'Algemene gegevens',
  '3': 'Gegevens VHE\'s',
  '4': 'Huurinformatie',
  '5': 'Financieel',
}

const ProjectDetail = () => {
  SwiperCore.use([Navigation])

  const navigate = useNavigate()
  const [printUrl,setPrintUrl] = useState(null)
  const [printPages,setPrintPages] = useState([ 1 ])
  const [modalOpen,setModalOpen] = useState(false)
  const [swiperOpen,setSwiperOpen] = useState(false)
  const [swiperSlide,setSwiperSlide] = useState(0)
  const isDesktop = useMediaQuery('(min-width: 600px)')

  const { id } = useParams()

  const selectPrintPage = (page, selected) => {
    const index = printPages.indexOf(page)
    if (selected && index < 0) {
      setPrintPages([ ...printPages, page ])
    } else if (!selected && index >= 0) {
      const p = [ ...printPages ]
      p.splice(index, 1)
      setPrintPages(p)
    }
  }

  const goPrint = () => {
    setPrintUrl(null)
    setTimeout(() => {
      setPrintUrl(`/projects/${id}/print/${printPages.join('-')}`)
    }, 100)
  }

  const goDownload = () => {
    setPrintUrl(null)
    window.open(`${config.server.baseUri}/project/id/${id}/format/pdf/pages/${printPages.join('-')}`)
  }

  const { isLoading, data: project } = useQuery(
    [ 'project', id ],
    () => projectsClient.one(id)
  )

  useEffect(() => {
    if (project && !project.error) {
      document.title = `Locatie: ${project.headline} | REND`
    }
  }, [project])

  if (isLoading) {
    return <Frontend>
      <div className="container">
        <div className="loader"></div>
      </div>
    </Frontend>
  }

  if (project.error) {
    navigate('/login')
    return <Frontend>
      <div className="container">
        <>{project.error}</>
      </div>
    </Frontend>
  }

  const media = [ ...project.media ]
  const thumbs = media.length > 5 ? media.slice(0, 5) : media

  const beleggers = []
  const finances = {}
  const financesTotal = {
    aflossing: 0,
    jaarlijkseAflossing: 0,
    huidigeLening: 0,
    hypotheek: 0
  }

  project.objects.forEach(object => {
    object.properties.beleggers.forEach(belegger => {
      if (!beleggers.includes(belegger.name)) {
        beleggers.push(belegger.name)
      }
    })

    object.properties.finances.forEach(finance => {
      if (!(finance.leningNummer in finances)) {
        finances[finance.leningNummer] = { ...finance }

        financesTotal.aflossing += finance.aflossing
        financesTotal.jaarlijkseAflossing += finance.jaarlijkseAflossing
        financesTotal.huidigeLening += finance.huidigeLening
        financesTotal.hypotheek += finance.hypotheek
      }
    })
  })

  return <Frontend>
    <div className="container">
      <div className="object-header">
        <div className="content">
          <h2>Locatie: {project.headline}</h2>
          <div className="meta">
            {beleggers.length > 1
              ? <>Eigenaars: {beleggers.join(', ')}</>
              : <>Eigenaar: {beleggers.length > 0 ? beleggers[0] : 'geen'}</>}
          </div>
          <div className="actions"><Link to="/" className="link">Terug naar het overzicht</Link></div>
        </div>
        <div className="right">
          <button type="button" id="print_button" className="blue solid button" onClick={() => setModalOpen(true)}>Afdrukken of downloaden</button>
        </div>
      </div>
    </div>

    {media.length > 0 && <div className="container">
      {isDesktop
        ? <div className={`mainsub ${numberToText(Math.max(Math.min(media.length, 5), 1))} ratio-16x9 rounded`}>
            {thumbs.map((file, index) => (
              <div key={`thumb-${index}`} className="item">
                <div onClick={() => { setSwiperSlide(index); setSwiperOpen(true) }} className="crop-image" style={{ backgroundImage: `url(${file.thumb})` }}></div>
              </div>
            ))}
            {media.length > 5 && <div className="actions">
              <div onClick={() => { setSwiperSlide(5); setSwiperOpen(true) }} className="button solid white">Alle foto's tonen</div>
            </div>}
          </div>
        : <div className="mainsub one ratio-16x9 rounded">
            <div key="thumb-0" className="item">
              <div onClick={() => { setSwiperSlide(0); setSwiperOpen(true) }} className="crop-image" style={{ backgroundImage: `url(${thumbs[0].thumb})` }}></div>
            </div>
            {media.length > 1 && <div className="actions">
              <div onClick={() => { setSwiperSlide(1); setSwiperOpen(true) }} className="button solid white">Alle foto's tonen</div>
            </div>}
          </div>
      }
    </div>}

    <div className="container">
      <div className="content-column">

        <h2>Algemeen</h2>

        {project.properties.introtext && <p>{project.properties.introtext}</p>}

        {project.properties.fulltext && <div dangerouslySetInnerHTML={{ __html: project.properties.fulltext }}/>}

        <h2>Gegevens VHE's</h2>


        <div className='mobiletables'>
          <Pandgegevens
            objects={project.objects}
            fields={fieldsPandgegevens}
            accordion
          />
        </div>

        <h3>Energielabel</h3>
        <div className='mobiletables'>
          <table className="table details">
            <tbody>
            {project.objects.map(object => (
              <tr key={object.id}>
                <td>{object.headline}</td>
                <td>{object.properties.energielabel ? <Energielabel label={object.properties.energielabel}/> : 'geen'}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>

        <h2>Huurinformatie</h2>

        {/* <table className="table">
          <thead>
            <tr>
              <th>VHE</th>
              <th>Huurder</th>
              <th>Huursom excl. BTW</th>
              <th>Huursom incl. BTW</th>
            </tr>
          </thead>
          <tbody>
            {project.objects.map(object => (
              <tr key={object.id}>
                <td>{object.headline}</td>
                <td>{object.properties.huurder || 'geen'}</td>
                <td>{object.properties.huurder && object.properties.huursomexcl ? formatCurrency(object.properties.huursomexcl) : '-'}</td>
                <td>{object.properties.huurder && object.properties.huursom ? formatCurrency(object.properties.huursom) : '-'}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}><strong>Totaal</strong></td>
              <td>{formatCurrency(project.objects.reduce((prev, object) => prev + (object.properties.huurder && object.properties.huursomexcl ? parseFloat(object.properties.huursomexcl) : 0), 0))}</td>
              <td>{formatCurrency(project.objects.reduce((prev, object) => prev + (object.properties.huurder && object.properties.huursom ? parseFloat(object.properties.huursom) : 0), 0))}</td>
            </tr>
          </tfoot>
        </table> */}
        <div className='mobiletables'>
          <div className="details-table">
            <div className="details-row details-head">
              <div className="w">VHE</div>
              <div className="w">Huurder</div>
              <div className="n">Huursom excl. BTW</div>
              <div className="n">Huursom incl. BTW</div>
            </div>
            <Accordion multiExpand>
              {project.objects.map(object => (
                <AccordionItem key={object.id}>
                  <AccordionSummary>
                    <div className="details-row">
                      <div className="w">{object.headline}</div>
                      <div className="w">{object.properties.huurder || 'geen'}</div>
                      <div className="n">{object.properties.huurder && object.properties.huursomexcl ? formatCurrency(object.properties.huursomexcl) : '-'}</div>
                      <div className="n">{object.properties.huurder && object.properties.huursom ? formatCurrency(object.properties.huursom) : '-'}</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <table className="table details">
                      <tbody>
                        <tr>
                          <td>Objectgroep</td>
                          <td>{object.properties.objectgroep}</td>
                          <td></td>
                        </tr>
                        {object.properties.huurder ? <tr>
                          <td>Aanvang huurcontract</td>
                          <td>{formatDate(object.properties.ingangsdatum)}</td>
                          <td></td>
                        </tr> : null}
                        {object.properties.huurder ? <tr>
                          <td>Verlengformule</td>
                          <td>{object.properties.verlengformule}</td>
                          <td></td>
                        </tr> : null}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </AccordionItem>
              ))}
            </Accordion>
            <div className="details-row details-foot">
              <div className="w"><strong>Totaal</strong></div>
              <div className="w">&nbsp;</div>
              <div className="n">{formatCurrency(project.objects.reduce((prev, object) => prev + (object.properties.huurder && object.properties.huursomexcl ? parseFloat(object.properties.huursomexcl) : 0), 0), true)}</div>
              <div className="n">{formatCurrency(project.objects.reduce((prev, object) => prev + (object.properties.huurder && object.properties.huursom ? parseFloat(object.properties.huursom) : 0), 0), true)}</div>
            </div>
          </div>
        </div>

        {/* <Pandgegevens
          objects={project.objects}
          fields={firstFields}
          accordion
        /> */}

        <h2>Financieel</h2>

        <h3>Totaal</h3>
        <div className='mobiletables'>
          <table className="table details">
            <tbody>
              <tr>
                <td>Aankoopsom</td>
                <td>{formatCurrency(project.objects[0].properties.aanschafwaardepr)}</td>
                <td></td>
              </tr>
              <tr>
                <td>WOZ Waarde</td>
                <td>{formatCurrency(project.objects[0].properties.wozwaarde)}</td>
                <td></td>
              </tr>
              <tr>
                <td>Taxatiewaarde</td>
                <td>{formatCurrency(project.objects[0].properties.taxatiewaardepr)}</td>
                <td></td>
              </tr>
              {project.objects[0].properties.geschattewaarde && <tr>
                <td>Geschatte Waarde</td>
                <td>{project.objects[0].properties.geschattewaarde && project.objects[0].properties.geschattewaarde !== '0' ? formatCurrency(project.objects[0].properties.geschattewaarde) : '-'}</td>
                <td></td>
              </tr>}
            </tbody>
          </table>
        </div>

        <h2>Hypotheek</h2>

        <div className='mobiletables'>
          <div className="details-table">
            <div className="details-row details-head">
              <div>Leningnummer</div>
              <div>Financierder</div>
              <div>Hypotheek bij aanvang</div>
              <div>Hypotheek actueel</div>
              <div>Hypothecaire inschrijving</div>
            </div>
            <Accordion multiExpand>
              {Object.keys(finances).map(nr => (
                <AccordionItem key={nr}>
                  <AccordionSummary>
                    <div className="details-row">
                      <div>{nr}</div>
                      <div>{finances[nr].bedrijf}</div>
                      <div>{formatCurrency(finances[nr].hypotheek, true)}</div>
                      <div>{finances[nr].huidigeLening ? formatCurrency(finances[nr].huidigeLening, true) : 'afgelost'}</div>
                      <div>{finances[nr].huidigeLening ? 'Ja' : 'Nee'}</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <table className="table details">
                      <tbody>
                        <tr>
                          <td>Hypotheek bij aanvang</td>
                          <td>{formatCurrency(finances[nr].hypotheek)}</td>
                        </tr>
                        <tr>
                          <td>Jaarlijkse aflossing</td>
                          <td>{formatCurrency(finances[nr].jaarlijkseAflossing, true)}</td>
                        </tr>
                        <tr>
                          <td>Rente</td>
                          <td>{formatNumber(finances[nr].rente)}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </AccordionDetails>
                </AccordionItem>
              ))}
            </Accordion>
            <div className="details-row details-foot">
              <div><strong>Totaal</strong></div>
              <div>&nbsp;</div>
              <div>{formatCurrency(financesTotal.hypotheek, true)}</div>
              <div>{financesTotal.huidigeLening ? formatCurrency(financesTotal.huidigeLening, true) : 'afgelost'}</div>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>

        {/* <table className="table">
          <thead>
            <tr>
              <th>Leningnummer</th>
              <th>Financierder</th>
              <th>Hypotheek actueel</th>
              <th>Hypothecaire inschrijving</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(finances).map((nr, index) => (
              <tr key={`finances-${index}`}>
                <td>{nr}</td>
                <td>{finances[nr].bedrijf}</td>
                <td>{finances[nr].huidigeLening ? formatCurrency(finances[nr].huidigeLening) : '-'}</td>
                <td>{finances[nr].huidigeLening ? 'Ja' : 'Nee'}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}><strong>Totaal</strong></td>
              <td>{financesTotal.huidigeLening ? formatCurrency(financesTotal.huidigeLening) : '-'}</td>
            </tr>
          </tfoot>
        </table>

        <Accordion multiExpand>
        {Object.keys(finances).map((nr, index) => (
          <AccordionItem key={`finances-${index}`}>
            <AccordionSummary>
              <h5>Leningnummer {nr}</h5>
            </AccordionSummary>
            <AccordionDetails>
              <table className="table details">
                <tbody>
                  <tr>
                    <td>Financierder</td>
                    <td>{finances[nr].bedrijf}</td>
                  </tr>
                  <tr>
                    <td>Hypotheek actueel</td>
                    <td>{finances[nr].huidigeLening ? formatCurrency(finances[nr].huidigeLening) : 'afgelost'}</td>
                  </tr>
                  <tr>
                    <td>Hypothecaire inschrijving</td>
                    <td>{finances[nr].huidigeLening ? 'Ja' : 'Nee'}</td>
                  </tr>
                  <tr>
                    <td>Hypotheek bij aanvang</td>
                    <td>{formatCurrency(finances[nr].hypotheek)}</td>
                  </tr>
                  <tr>
                    <td>Jaarlijkse aflossing</td>
                    <td>{formatCurrency(finances[nr].jaarlijkseAflossing, true)}</td>
                  </tr>
                  <tr>
                    <td>Rente</td>
                    <td>{finances[nr].rente}%</td>
                  </tr>
                </tbody>
              </table>
            </AccordionDetails>
          </AccordionItem>
        ))}
        </Accordion> */}


        <h3>Loan to value</h3>

        <div className='mobiletables'>
          <table className="table details">
            <tbody>
              <tr>
                <td>Loan to value</td>
                <td>
                  {project.objects[0].properties.taxatiewaardepr && project.objects[0].properties.taxatiewaardepr !== '-' && financesTotal.huidigeLening
                    ? formatNumber(100 * financesTotal.huidigeLening / project.objects[0].properties.taxatiewaardepr, 2) + '%'
                    : '-'
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <h2>Wat wilt u afdrukken?</h2>

      {Object.keys(printablePages).map(key => (
        <div className="checkbox" key={`page${key}`}>
          <input type="checkbox" name="what[]" value={key} id={`what_${key}`} checked={printPages.includes(parseInt(key))} onChange={e => selectPrintPage(parseInt(key), e.target.checked)}/>
          <label htmlFor={`what_${key}`}>Pagina {key}: {printablePages[key]}</label>
        </div>
      ))}

      <div style={{ marginTop: 20 }}>
        <button type="submit" className="solid blue button" onClick={goPrint}>Afdrukken</button>
        <button type="submit" className="outlined button" onClick={goDownload}>Download PDF</button>
      </div>

      {printUrl && <iframe title="Print" style={{ border: 'none', width: '100%', height: 0, position: 'absolute' }} src={printUrl} />}
    </Modal>

    <Modal open={swiperOpen} fullscreen onClose={() => setSwiperOpen(false)}>
      {swiperOpen && <Swiper className="photo-slideshow" initialSlide={swiperSlide} navigation>
        {media.map((image, index) => (
          <SwiperSlide key={`media-${index}`}>
            <div className="photo-slideshow-slide"><img src={image.url} alt={image.alt}/></div>
          </SwiperSlide>
        ))}
      </Swiper>}
    </Modal>
  </Frontend>

}

export default ProjectDetail
