import { useEffect, useRef } from "react"
import useBulk from "../hooks/useBulk"
import Card from "./Card"
import Checkbox from "./Checkbox"

const ObjectsGallery = ({ items, mode, page, onBulk, onClearFilters }) => {
  const bulkAllRef = useRef()

  const { bulk, bulkSpan, setChecked, setAllChecked, bulkAll, bulkAny } = useBulk(
    items && items.map instanceof Function ? items.map(i => i.id) : null,
    bulkAllRef,
    page
  )

  useEffect(() => {
    if (onBulk) {
      const ids = []
      const idsSpan = []
      for (let id in bulk) {
        if (bulk[id]) ids.push(id)
      }
      for (let id in bulkSpan) {
        if (bulkSpan[id]) idsSpan.push(id)
      }
      onBulk(bulkAny, bulkAll, ids, idsSpan)
    }
  }, [bulkAny, bulkAll, bulk])

  return <>
    <div className="flex">
      <div>
        {mode === 'projects' && <Checkbox
          label="Selecteer alles"
          type="checkbox"
          name="items[]"
          value=""
          reference={bulkAllRef}
          id={`items_all`}
          checked={bulkAll}
          onChange={(e) => setAllChecked(e.target.checked)}
        />}
      </div>
      <div>
        <a className="link" onClick={() => onClearFilters && onClearFilters()}>Wis alle filters</a>
      </div>
    </div>
    <div className="grid gallery">
      {items?.map(item => {
        return <Card
          key={item.id}
          title={item.headline}
          meta={item.properties.plaats}
          linkTo={`/${mode}/${item.id}`}
          image={item.media.length > 0 ? item.media[0].thumb : null}
          extra={mode === 'projects' && <Checkbox name="items" value={item.id} id={`items_${item.id}`} checked={!!bulk[item.id]} onChange={(e) => setChecked(item.id, e.target.checked)}/>}
        />
      })}
    </div>
  </>
}

export default ObjectsGallery
